import { FC, useMemo, useState } from 'react';
import { ClientFormUserRole, ClientFormUserRoleKeys } from '../../models/ClientFormUserRoles';
import User from '../../models/User';
import CheckIcon from '../shared/icon/CheckIcon';
import ProfileAvatar, { AvatarRole, ImageSize } from '../shared/profile-image/ProfileAvatar';
import { useRecoilValue } from 'recoil';
import { currentUserAtom } from '../../recoil/atoms/Auth';
import { useTranslation } from 'react-i18next';
import { ClientFormUserOnly } from '../../models/ClientFormUser';
import { Option } from '../Option';
import { SelectListMenu } from '../shared/SelectListMenu';
import { mouseAndKeyboardCallbackProps } from '../../utils/ComponentUtils';
import useAvatarRoleNames from '../../hooks/useAvatarRoleNames';

type ProfileImageStackProps = {
  users?: ClientFormUserOnly[];
  size?: ImageSize;
  caption?: string;
  displayLimit?: number;
};

const ProfileImageStack: FC<ProfileImageStackProps> = (props) => {
  const { users = [], size = ImageSize.S, caption, displayLimit = 3 } = props;
  const currentUser = useRecoilValue(currentUserAtom);
  const { t } = useTranslation(['common']);
  const [showMore, setShowMore] = useState(false);

  let moreSizeCss = 'w-12 h-12 text-dpm-14';

  switch (size) {
    case ImageSize.XS:
      moreSizeCss = 'min-w-8 w-8 h-8 text-dpm-12';
      break;
    case ImageSize.S:
      moreSizeCss = 'min-w-10 w-10 h-10 text-dpm-12';
      break;
    default:
      moreSizeCss = 'min-w-12 w-12 h-12 text-dpm-14';
      break;
  }

  // Group users by id and accumulate their roles, then sort to place current user first
  const groupedUsers = useMemo(() => {
    const userMap = new Map<
      string,
      {
        user: ClientFormUserOnly;
        roles: AvatarRole[];
      }
    >();

    users.forEach((user) => {
      if (!userMap.has(user.id!)) {
        userMap.set(user.id!, { user, roles: [] });
      }
      const userData = userMap.get(user.id!);
      if (userData) {
        const existingRoleIndex = userData.roles.findIndex((r) => r.role === user.role && r.requiresAction === !!user.requiresAction);
        if (existingRoleIndex === -1) {
          userData.roles.push({
            role: user.role!,
            requiresAction: !!user.requiresAction,
            roleOrder: user.sortOrder,
            isStepRole: !!user.formSectionId,
          });
        }
      }
    });

    const groupedUsersArray = Array.from(userMap.values());
    return groupedUsersArray.sort((a, b) => (a.user.id === currentUser?.id ? -1 : b.user.id === currentUser?.id ? 1 : 0));
  }, [users, currentUser]);

  const usersToDisplay = groupedUsers.slice(0, displayLimit);
  const remainingUsers = groupedUsers.slice(displayLimit);

  const RemainingUsersListRenderer = useMemo(() => {
    return function ListRenderer(props: Option<string, string | number>) {
      const userIndex = remainingUsers.findIndex((x) => x.user.id === props.id);
      const { user, roles } = remainingUsers[userIndex];
      const roleNames = useAvatarRoleNames(roles || [], t);
      return (
        <div key={user.id} className={`flex p-1 ${userIndex === remainingUsers.length - 1 ? 'border-b-0' : 'border-b'} border-gray-6`}>
          <div className="px-1">
            <ProfileAvatar data-cy={`user-${user.id}`} user={user as User} roles={roles} size={size} />
          </div>
          <div className="px-1">
            <span className="block">
              {user.firstName} {user.lastName}
            </span>
            <span className="text-dpm-12 text-gray-2">{roleNames}</span>
          </div>
        </div>
      );
    };
  }, [remainingUsers, size, t]);

  return (
    <div className="flex h-full" data-cy="profile-stack">
      <div className="flex flex-col self-center">
        <span className={`text-dpm-12 text-center font-medium ${caption ? 'mb-3' : ''}`} data-cy="caption" aria-label={t('aria-label.caption')}>
          {caption}
        </span>
        <div className="profile-image-stack relative flex justify-items-center self-center">
          {groupedUsers.length === 0 && <ProfileAvatar data-cy="no-users" data-user-count={0} size={size} />}
          {usersToDisplay.map(({ user, roles }, index) => {
            return (
              <div key={user.id} className="relative">
                <ProfileAvatar
                  data-cy={`user-${user.id}`}
                  data-user-count={groupedUsers.length}
                  user={user as User}
                  roles={roles}
                  size={size}
                  withMargin={index > 0 && groupedUsers.length > 1}
                />
              </div>
            );
          })}
          {remainingUsers.length > 0 && (
            <>
              <SelectListMenu
                isOpen={showMore}
                options={remainingUsers.map(({ user }) => {
                  return {
                    id: user.id || '',
                    value: `${user.firstName} ${user.lastName}`,
                    text: `${user.firstName} ${user.lastName}`,
                  };
                })}
                customListItemRenderer={RemainingUsersListRenderer}
                onBlur={() => setShowMore(false)}
                className="border-none text-left"
                width="w-fit"
              >
                {(triggerProps) => (
                  <div {...triggerProps} className="relative">
                    <div
                      {...mouseAndKeyboardCallbackProps((e) => {
                        e?.preventDefault();
                        e?.stopPropagation();
                        setShowMore(!showMore);
                      })}
                      className={`bg-gray-5 relative -ml-2 -mt-[1px] inline-flex items-center justify-center rounded-full border-2 border-white p-1 pt-[5px] font-medium text-black ${moreSizeCss} cursor-pointer`}
                    >
                      +{remainingUsers.length}
                    </div>
                  </div>
                )}
              </SelectListMenu>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export { ImageSize, ProfileImageStack };
